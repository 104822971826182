
@tailwind base;
@tailwind components;
@tailwind utilities;

.img_view{
  background-image: url('./assets/green_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.ipadMax_logo{
  background-image: url('./assets/Surface\ Laptop\ Studio.png');
  background-size: contain;
  background-repeat: no-repeat;

}
.bg_color_green_animation{
  background-image: url('./assets/green_bg_image_animation.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.about_our_people_bg{
  background-image: url('./assets/aboutUs/co-women-discussion.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.ideas_image{
  background-image: url('./assets/ideas_in.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.vajrastra_logo{
  background-image: url('./assets/vajrastra_logo.png');
  background-size: contain;
    background-repeat: no-repeat;
}
.discover_logo{
  background-image: url('./assets/discover_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.nischay_logo{
  background-image: url('./assets/nischai_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.varadhi_logo{
  background-image: url('./assets/varadhi_logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.team_logos img:nth-child(even){
  margin-top: 20px;
}
.team_logos img:nth-child(odd){
  margin-top: -10px;
}
.team_logos img:first-child{
  margin-top: 0;
}

.border_top_animation{
  transition:  ease-in-out;
  animation: border_anim 3s linear forwards;

}
@keyframes border_anim {
	0%{
		width: 0px;
	}
	100%{
		width: 200px;
	}
}
#scroll-bg-icons {
  text-align: center;
  
  /* animation properties */
  -moz-transform: translateY(10%);
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  
  -moz-animation: my-animation 10s linear infinite alternate;
  -webkit-animation: my-animation 10s linear infinite alternate;
  animation: my-animation 10s linear infinite alternate;
}

/* for Firefox */


@keyframes my-animation {
  from {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -moz-transform: translateY(10%);
    -webkit-transform: translateY(0%);
    transform: translateY(30%);
  }
}

.service_card_border {
  transition: all 1s;
  position: relative;
}

.service_card_border:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: 1;
  border-top: 4px solid #FBE204;
  animation: grow 3s linear;
  animation-fill-mode: forwards;
}

@keyframes grow {
  100% {
    width: 100%;
  }
}



.text {
    background: linear-gradient(90deg, #184e06 0%, #5fbd3d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-top {
  background: linear-gradient(360deg, #184e06 0%, #5fbd3d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service{
  background-image: url('./assets/WhyChooseUsLogos/services.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.bg_lime_green,.bg_green:nth-child(even) .bg_imag_green{
  border:20px solid #73B639;
  }
  .bg_leaf_green,.bg_green:nth-child(odd) .bg_imag_green{
    border:20px solid #197611;
    }
.bg_green strong{
  text-transform: uppercase;
}
.rc-anchor{
  background: none;
}

 .cssanimation, .cssanimation span {
  animation-duration: 1s;
}


.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(2%) ;
  }
  to { opacity: 1 }
}


@keyframes morqueeScroll {
  from {
    transform: translateX(0);
  }
  to{
    transform: translateX(-100%);
  }
}

.logo-slide{
  display: inline-flex;
 animation: 20s morqueeScroll infinite linear;
}
